import authConfig from './auth-config.json';

export const environment = {
  production: false,
  redirect_uri: 'https://np.insights.bayer.com/callback',
  apiUrl: 'https://api.uat.insights.bayer.com',
  apiKey: '4__WM5m7wB-ihwAD8zzznkiA',
  clientId: 'SbHVbzsri-lfgURO8nCGd-kE',
  issuer_url: 'https://fidm.eu1.gigya.com/oidc/op/v1.0',
  ...authConfig,
};
